import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../../../components/Layout'
import dallas from '../../../assets/images/dallas.jpg'
import SEO from '../../../components/seo'
import { Dropdown, DropdownButton } from 'react-bootstrap'
// var moment = require('moment')

class EllisIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const services = data.allSanityServiceArea.edges

    // console.log(services)

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Ellis County | J&J Automatic Gate Service & Design"
          description="If You Need Gate Installation and Repair in Ellis County, TX, Call  for J & J Gates Service and Design. We are Commercial and Residential."
          // keywords={[`blog`, `gatsby`, `javascript`, `react`]}
        />
        <div id="blog-hero-section" className="blog-hero-section text-center">
          <h1>Professional Gate Services in Ellis County, TX</h1>
        </div>
        <div className="container my-5">
          <DropdownButton
            id="dropdown-basic-button"
            title="Select Ellis County Areas"
          >
            {services.map(({ node }) => {
              const title = node.title
              return (
                <Dropdown.Item href={`/${node.slug.current}`}>
                  {title}
                </Dropdown.Item>
              )
            })}
          </DropdownButton>
          <img src={dallas} className="float-right" alt="dallas" />
          <p className="mt-3">
            When you need commercial or residential gate services in Ellis
            County, TX, make J & J Gates Service and Design your first choice.
            Our experienced technicians specialize in all areas of automated
            gate installation, fabrication, repair, and more. From stunning
            wrought iron gates to high-security access systems, we do it all!{' '}
            <br />
            <strong>
              <i>
                Call us at{' '}
                <span className="com-color">
                  <a href="tel:(817) 466-2794">(817) 466-2794</a>
                </span>{' '}
                to learn more about our wide variety of gate services in Ellis
                County, TX!
              </i>
            </strong>
          </p>
          <h2>Communities We Serve</h2>
          <p>
            With multiple locations in the Dallas-Fort Worth metroplex, J & J
            Gates Service and Design are proud to serve home and business owners
            throughout the region. No matter where you are in Ellis County, TX,
            just give our team a call and we will be on our way! Contact us if
            you need professional gate services in one of the following
            communities:
          </p>
          <div className="ellis-list-areas list-areas">
            <ul>
              {services.map(({ node }) => {
                const title = node.title
                return (
                  <li>
                    <Link to={node.slug.current}>
                      <i className="fa fa-chevron-right fa-sm mr-2"></i> {title}
                    </Link>
                  </li>
                )
              })}
            </ul>
          </div>
          <h2>Comprehensive Gate Services Near You</h2>
          <p>
            Are you looking for a wooden, ranch-style gate for your property’s
            entryway? Do you need a chain link gate and fence system to keep
            trespassers away from costly equipment? Whether you want an ornate,
            custom wrought iron gate or need a garage door repaired, our
            technicians handle all types of gate installation and repair. <br />
            <i> We even service gates that our crew did not install!</i>
          </p>
          <p>
            <strong>
              In every job we do, we are fully committed to your total
              satisfaction
            </strong>
            . After all, most people use their gate systems every single day, so
            they need a reliable, secure gate. We will make sure your automatic
            gate is set up properly and all components are in smooth, working
            order. Before installation, our design team can help you determine
            exactly what type of gate would suit your property, needs, and
            budget best. To see some examples of our work, check out our{' '}
            <Link to="/gallery"> gallery</Link> or explore our website for
            ideas.{' '}
            <strong>
              We look forward to helping you find the perfect gate system for
              your home, business, or other property in Ellis County, TX
            </strong>
            .
          </p>
          <p>
            Call J & J Gates Service and Design today at{' '}
            <a href="tel:(817) 466-2794">(817) 466-2794</a> for superior gate
            installation and repair near you. With more than a decade of
            experience, our staff has the skills and techniques to expertly
            install all kinds of gates, access systems, and more. Plus, you can
            always count on high-quality materials from{' '}
            <Link to="/gates/gate-brands">reputable manufacturers</Link>.
          </p>
          <p>
            <Link to="/service-area/texas">Return to Texas</Link>
          </p>
        </div>
      </Layout>
    )
  }
}

export default EllisIndex

export const EllisIndexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allSanityServiceArea(
      filter: {
        serviceCategory: { elemMatch: { county: { eq: "Ellis County" } } }
      }
    ) {
      edges {
        node {
          id
          title
          slug {
            current
          }
          _updatedAt
          author {
            id
            name
          }
          serviceCategory {
            id
            county
          }
        }
      }
    }
  }
`
